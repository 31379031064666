
import { defineComponent } from "vue";
import navigation from "@/components/navigation.vue";
import { ICommanderDeviceUpdate } from "@/interface/commander.interface";
import { CommanderDevice } from "@/service/create-data-commander";
export default defineComponent({
  components: {
    navigation,
  },
  mounted() {
    this.getData(this.nit);
  },
  data() {
    return {
      nit: "" as string,
      editDataCustomer: {} as ICommanderDeviceUpdate,
    };
  },
  methods: {
    async updateDataCommander(nit: string) {
      try {
        nit = this.$route.params.nit.toString();
        const dataUpdated: ICommanderDeviceUpdate = this.editDataCustomer;
        const response = await CommanderDevice.updateData(nit, dataUpdated);
        alert(`Editado !!! ${response.status}`);
      } catch (error) {
        alert(error);
      }
    },
    async getData(nit: string) {
      try {
        nit = this.$route.params.nit.toString();
        const getDataByNit = await CommanderDevice.getUniqueDataByNit(nit);
        this.editDataCustomer.nit = getDataByNit.data.nit;
        this.editDataCustomer.business_name = getDataByNit.data.business_name;
        this.editDataCustomer.tradename = getDataByNit.data.tradename;
        this.editDataCustomer.activation_date =
          getDataByNit.data.activation_date;
        this.editDataCustomer.end_date = getDataByNit.data.end_date;
        this.editDataCustomer.city = getDataByNit.data.city;
        this.editDataCustomer.department = getDataByNit.data.department;
        this.editDataCustomer.phone_number = getDataByNit.data.phone_number;
        this.editDataCustomer.number_of_devices =
          getDataByNit.data.number_of_devices;
        this.editDataCustomer.server_trackId = getDataByNit.data.server_trackId;
        this.editDataCustomer.lifetime = getDataByNit.data.lifetime;
        this.editDataCustomer.address = getDataByNit.data.address;
      } catch (error) {
        alert(error);
      }
    },
  },
});
