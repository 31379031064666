
import { defineComponent } from "vue";
import navigation from "@/components/navigation.vue";
import { CommanderDevice } from "../service/create-data-commander";
import { ICommanderDeviceCreate } from "@/interface/commander.interface";
import {irApi} from '../service/environmentVariables'
export default defineComponent({
  components: {
    navigation,
  },
  data() {
    return {
      generalData: [] as Array<ICommanderDeviceCreate>,
      searchCustomer: "" as string,
    };
  },
  mounted() {
    this.getData();
  },

  methods: {
    async getData() {
      try {

  
        const response = await CommanderDevice.getData();

        this.generalData = response.data;
        alert(
          `Se encontraron ${this.generalData.length} Clientes con comanderas moviles`
        );
      } catch (error) {
        alert("Error: " + error);
      }
    },
    editItem(nit: string) {
      this.$router.push(`/edit-data/${nit}`);
    },
    async searchOneCustomer() {
      this.searchCustomer = this.searchCustomer.toUpperCase();
      if (this.searchCustomer === "") {
        await this.getData();
      }
      if (this.searchCustomer && this.searchCustomer.trim() != null) {
        console.log(this.searchCustomer);
        this.generalData = this.generalData.filter((item) => {
          return (
            item.server_trackId.includes(this.searchCustomer) ||
            item.nit.includes(this.searchCustomer)  ||
            item.business_name.includes(this.searchCustomer)
          );
        });
        console.log(this.generalData)
      }
    },
  },
});
