
import { defineComponent } from "vue";
import { ICommanderDeviceCreate } from "../interface/commander.interface";
import { CommanderDevice } from "../service/create-data-commander";
import navigation from '@/components/navigation.vue';
export default defineComponent({
    components: {
        navigation
    },

  data() {
    return {
      commanderDevice: {} as ICommanderDeviceCreate,
    };
  },
  methods: {
    async sendDataCommander() {
      try {
        let business_name = this.commanderDevice.business_name;
        this.commanderDevice.business_name = business_name.toUpperCase();
        let tradename = this.commanderDevice.tradename;
        this.commanderDevice.tradename = tradename.toUpperCase();
        let city = this.commanderDevice.city;
        this.commanderDevice.city = city.toUpperCase();
        let department = this.commanderDevice.department;
        this.commanderDevice.department = department.toUpperCase();
        let address = this.commanderDevice.address;
        this.commanderDevice.address = address.toUpperCase();

        const responseData = await CommanderDevice.sendData(
          this.commanderDevice
        );
        if (responseData.data.response === undefined) {
          alert("¡Cliente Creado !");
        } else {
          alert(responseData.data.response);
        }
        this.commanderDevice.nit = "";
        this.commanderDevice.business_name = "";
        this.commanderDevice.tradename = "";
        this.commanderDevice.city = "";
        this.commanderDevice.department = "";
        this.commanderDevice.address = "";
        this.commanderDevice.phone_number = "";
        this.commanderDevice.server_trackId="";
        this.commanderDevice.number_of_devices=0;
      } catch (error) {
        alert(error);
        console.log(error);
      }
    },
  },
});
